import { infoBar } from "./infobar";

const mainAssetsUrl = 'https://strapi-dcb6d488.needmorecoffee.it';
const strapiApiEndpoint = `${mainAssetsUrl}/api`;

let strapi = {
    assetsUrl: mainAssetsUrl,
    apiEndpoint: strapiApiEndpoint,
    genericInforollEndpoint: `${strapiApiEndpoint}/generic-inforoll`,
    inforollData: [],
    toCallWhenReady: [],
    toCallWhenUpdated: [],
    firstInit: true,

    fetchGenericInforoll: async () => {
        const response = await fetch(strapi.genericInforollEndpoint);
        const responseJson = await response.json();
        strapi.inforollData = responseJson.data;
        if (strapi.firstInit) {
            strapi.toCallWhenReady.forEach(toCall => { toCall(); });
            strapi.firstInit = false;
        }
        strapi.toCallWhenUpdated.forEach(toCall_1 => { toCall_1(); });
    },

    getInforollRows: () => {
        return strapi.inforollData?.attributes?.infoRoll;
    },

    getInforollAdvanceTimeout: () => {
        return strapi.inforollData?.attributes?.advanceTimeout;
    },

    callWhenReady: (fn) => {
        strapi.toCallWhenReady.push(fn);
    },

    callWhenUpdated: (fn) => {
        strapi.toCallWhenUpdated.push(fn);
    },

    callWhenDonationsUpdated: (fn) => {
        strapi.toCallWhenDonationsUpdated.push(fn);
    },
}

export { strapi };
window.strapi = strapi;

setTimeout(strapi.fetchGenericInforoll, 200);
setInterval(strapi.fetchGenericInforoll, 15000);